exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-cabinetry-tsx": () => import("./../../../src/pages/services/cabinetry.tsx" /* webpackChunkName: "component---src-pages-services-cabinetry-tsx" */),
  "component---src-pages-services-carpet-tsx": () => import("./../../../src/pages/services/carpet.tsx" /* webpackChunkName: "component---src-pages-services-carpet-tsx" */),
  "component---src-pages-services-flooring-tsx": () => import("./../../../src/pages/services/flooring.tsx" /* webpackChunkName: "component---src-pages-services-flooring-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-services-stairs-tsx": () => import("./../../../src/pages/services/stairs.tsx" /* webpackChunkName: "component---src-pages-services-stairs-tsx" */)
}

